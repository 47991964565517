<template>
  <b-form class="my-3" @submit.stop.prevent="onSubmit">
    <b-card title="Profile">
      <b-card-text>
        <p class="lead">
          Hi, before you begin the pre-assessment questionnaire please complete
          the below profile questions.
        </p>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="offset-sm-1 col-form-label">First name</label>
              <div class="col-sm-8">
                <b-form-input
                  v-model="answers.firstname"
                  placeholder="Enter your first name"
                ></b-form-input>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="offset-sm-1 col-sm-3 col-form-label"
                >Last name</label
              >
              <div class="col-sm-8">
                <b-form-input
                  v-model="answers.lastname"
                  placeholder="Enter your last name"
                ></b-form-input>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label"
                >Sex at birth
                <b-icon
                  v-b-tooltip.hover.right="
                    'We require this information as your sex will affect some of the risk scores we use to estimate your health status.'
                  "
                  icon="exclamation-circle-fill"
                ></b-icon
              ></label>
              <div class="col-sm-6">
                <b-button-group>
                  <b-form-radio-group
                    v-model="answers.gender"
                    :options="[
                      { text: 'Female', value: '1' },
                      { text: 'Male', value: '2' },
                    ]"
                    buttons
                  ></b-form-radio-group>
                </b-button-group>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group row">
              <label class="offset-sm-1 col-sm-3 col-form-label"
                >Ethnicity
                <b-icon
                  v-b-tooltip.hover.right="
                    'This information is used to ensure correct calculations for certain tests'
                  "
                  icon="exclamation-circle-fill"
                ></b-icon
              ></label>
              <div class="col-sm-8">
                <b-form-select
                  v-model="answers.ethnicity"
                  :options="[
                    { text: 'Not stated', value: '0' },
                    { text: 'White', value: '1' },
                    { text: 'Indian (north & south)', value: '2' },
                    { text: 'Pakistani', value: '3' },
                    { text: 'Bangladeshi', value: '4' },
                    {
                      text: 'Other Asian (Japanese & Polynesian)',
                      value: '5',
                    },
                    { text: 'Black Caribbean', value: '6' },
                    { text: 'Black African', value: '7' },
                    { text: 'Chinese (Oriental & Hong Kong)', value: '8' },
                    { text: 'Other ethnic group', value: '9' },
                  ]"
                ></b-form-select>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Email</label>
          <div class="col-sm-6">
            <b-form-input
              v-model="answers.email"
              placeholder="Enter your email"
            ></b-form-input>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Date of birth</label>
          <div class="col-sm-2">
            <b-form-select
              v-model="answers.dobDay"
              :options="days"
            ></b-form-select>
          </div>
          <div class="col-sm-2">
            <b-form-select
              v-model="answers.dobMonth"
              :options="months"
            ></b-form-select>
          </div>
          <div class="col-sm-2">
            <b-form-select
              v-model="answers.dobYear"
              :options="rangeOfYears"
            ></b-form-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Business division</label>
          <div class="col-sm-6">
            <b-form-select
              v-model="answers.businessDivision"
              :options="businessDivisionsOptions"
            ></b-form-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Site</label>
          <div class="col-sm-6">
            <b-form-select
              v-model="answers.site"
              :options="siteOptions"
            ></b-form-select>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-6 col-form-label">Occupation</label>
          <div class="col-sm-6">
            <b-form-select
              v-model="answers.occupation"
              :options="occupationOptions"
            ></b-form-select>
          </div>
        </div>
        <!-- <div class="form-group row">
          <label class="col-sm-6 col-form-label"
            >What is your biggest health or lifestyle concern?</label
          >
          <div class="col-sm-6">
            <b-form-select
              v-model="answers.medicalConcern"
              :options="[
                { text: 'N/A', value: 'na' },
                { text: 'Fitness', value: 'concernfitness' },
                { text: 'Diet', value: 'concernnutrition' },
                { text: 'Sleep', value: 'concernsleep' },
                { text: 'Alcohol', value: 'concernalcohol' },
                { text: 'Smoking', value: 'concernsmoking' },
                {
                  text: 'Stress/Anxiety',
                  value: 'concernstress',
                },
                {
                  text: 'Illness - Cardiovascular Disease',
                  value: 'concerndisease',
                },
                { text: 'Other', value: 'concernother' },
              ]"
            ></b-form-select>
          </div>
        </div> -->
      </b-card-text>
      <b-card-footer>
        <!-- <b-button @click="setStep(4)" class="btn btn-hruk-btn" variant="primary"
          >Previous</b-button
        > -->
        <b-button
          type="submit"
          class="btn float-right"
          v-bind:disabled="disabled"
          >Next</b-button
        >
      </b-card-footer>
    </b-card>
  </b-form>
</template>

<script>
import { savePatient } from "./../modules/Patient";
import axios from "axios";
export default {
  name: "ProfileComponent",
  data: () => ({
    answers: {
      firstname: null,
      lastname: null,
      ethnicity: null,
      gender: null,
      dobDay: null,
      dobMonth: null,
      dobYear: null,
      businessDivision: null,
      site: null,
      occupation: null,
      // medicalConcern: "na",
    },
    days: [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ],
    months: [
      { text: "January", value: "01" },
      { text: "February", value: "02" },
      { text: "March", value: "03" },
      { text: "April", value: "04" },
      { text: "May", value: "05" },
      { text: "June", value: "06" },
      { text: "July", value: "07" },
      { text: "August", value: "08" },
      { text: "September", value: "09" },
      { text: "October", value: "10" },
      { text: "November", value: "11" },
      { text: "December", value: "12" },
    ],
    businessDivisionsOptions: [],
    siteOptions: [],
    occupationOptions: [],
  }),
  validations: {
    answers: {},
  },
  async created() {
    // const businessDivision = await axios.get(
    //   `${this.$store.state.url}/apiv1/businessdivision`,
    //   {
    //     params: {},
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state.token}`,
    //     },
    //   }
    // );
    const config = {
      headers: { Authorization: `Bearer ${this.$store.state.token}` },
    };
    const body = {
      campaignCode: this.$store.state.campaignCode,
    };
    const businessDivision = await axios.post(
      `${this.$store.state.url}/apiv1/businessdivision`,
      body,
      config
    );
    this.businessDivisionsOptions = businessDivision.data.map((elem) => ({
      text: elem.name,
      value: elem.id,
    }));
    // const site = await axios.get(`${this.$store.state.url}/apiv1/site`, {
    //   params: {},
    //   headers: {
    //     Authorization: `Bearer ${this.$store.state.token}`,
    //   },
    // });
    const site = await axios.post(
      `${this.$store.state.url}/apiv1/site`,
      body,
      config
    );
    this.siteOptions = site.data.map((elem) => ({
      text: elem.name,
      value: elem.id,
    }));
    // const occupation = await axios.get(
    //   `${this.$store.state.url}/apiv1/occupation`,
    //   {
    //     params: {},
    //     headers: {
    //       Authorization: `Bearer ${this.$store.state.token}`,
    //     },
    //   }
    // );
    const occupation = await axios.post(
      `${this.$store.state.url}/apiv1/occupation`,
      body,
      config
    );
    this.occupationOptions = occupation.data.map((elem) => ({
      text: elem.occupation,
      value: elem.id,
    }));
  },
  methods: {
    async onSubmit() {
      this.$v.answers.$touch();
      if (this.$v.answers.$anyError) {
        return;
      }
      const subAnswers = {
        campaigncode: this.$store.state.campaignCode,
        firstname: this.answers.firstname,
        lastname: this.answers.lastname,
        sex: this.answers.gender,
        ethnicity: this.answers.ethnicity,
        email: this.answers.email,
        dob: `${this.answers.dobYear}/${this.answers.dobMonth}/${this.answers.dobDay}`,
        businessdivision: this.answers.businessDivision,
        site: this.answers.site,
        occupation: this.answers.occupation,
      };
      const patient = await savePatient(subAnswers);
      if (patient.casesessionid) {
        this.$store.dispatch("setSession", patient.casesessionid);
        this.$store.dispatch("setStep", 2);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.answers[name];
      return $dirty ? !$error : null;
    },
  },
  computed: {
    disabled: function () {
      return this.answers.firstname === null ||
        this.answers.lastname === null ||
        this.answers.ethnicity === null ||
        this.answers.gender === null ||
        this.answers.dobDay === null ||
        this.answers.dobMonth === null ||
        this.answers.dobYear === null
        ? true
        : false;
    },
    rangeOfYears() {
      const currentYear = new Date().getFullYear();
      let range = (start, stop, step) =>
        Array.from(
          { length: (stop - start) / step + 1 },
          (_, i) => start + i * step
        );
      range = range(currentYear - 17, currentYear - 90, -1);
      return range.map(String);
    },
  },
};
</script>
